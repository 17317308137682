<template>
  <div v-if="user" class="main-view">
    <page-header />

    <div class="page">
      <page-info />

      <div v-show="tabs.length" class="tabs">
        <router-link
          v-for="tab, index in tabs"
          :key="index"
          :to="{ name: tab.to }">
          <el-button
            :type="isTabActive(tab) ? 'info' : undefined"
            size="medium"
            round>
            {{ tab.name }}
          </el-button>
        </router-link>
      </div>

      <router-view />
    </div>

    <page-footer />
  </div>
</template>

<script>
import PageInfo from '@/components/layout/PageInfo'
import PageHeader from '@/components/layout/PageHeader'
import PageFooter from '../components/layout/PageFooter'

export default {
  components: {
    PageInfo,
    PageHeader,
    PageFooter
  },

  computed: {
    hideVerificationNotification () {
      return this.$store.state.app.hideVerificationNotification
    },

    tabs () {
      return this.$store.state.page.tabs
    },

    verificationZero () {
      return ['REGISTERED', 'ACTIVE'].includes(this.user?.status) && !this.user?.inVerification
    },

    user () {
      return this.$store.state.app.user
    },

    activeTab () {
      return this.$route.name
    }
  },

  methods: {
    isTabActive (tab) {
      return tab.to === this.$route.name
    },

    onVerificationClose () {
      this.$store.commit('app/setHideVerificationNotification', true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-view {
    padding-top: 100px;
    padding-bottom: 60px;
    .page {
      padding: 0 20px 20px 20px;
    }

    .tabs {
      padding-bottom: 5px;
      margin-bottom: 15px;
      overflow-x: auto;
      display: flex;
      flex-wrap: nowrap;
      > a + a {
        margin-left: 10px;
      }
    }
  }
</style>
