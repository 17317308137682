<template>
  <div class="text" :class="type">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'body'
    }
  }
}
</script>

<style lang="scss" scoped>
  .text {
    &.h1 {
      font-size: 24px;
      line-height: 1;
      font-weight: bold;
    }
  }
</style>
