<template>
  <div class="page-info">
    <div class="upper">
      <div class="left-side">
        <portal-target name="start-actions" />

        <router-link v-if="back" :to="back" class="back-link">
          <BackSvg />
        </router-link>

        <typography class="title" type="h1">{{ title }}</typography>
      </div>

      <div class="actions">
        <portal-target name="actions" />
        <portal-target name="filter-button" />
      </div>
    </div>

    <portal-target name="applied-filters" />
  </div>
</template>

<script>
import BackSvg from '@/assets/images/icons/back.svg'
import Typography from '@/components/common/ui/Typography'

export default {
  components: {
    BackSvg,
    Typography
  },

  data () {
    return {
      openFilters: false
    }
  },

  computed: {
    back () {
      return this.$store.state.page.back
    },

    title () {
      return this.$store.state.page.title
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-info {
    margin-bottom: 20px;
    .upper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-side {
        display: flex;
        align-items: center;
        .title {
          margin-right: 20px;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #29282A;
        }
        .back-link {
          display: block;
          padding: 8px 5px 2px 0;
          margin-right: 5px;
        }
      }
      .actions {
        display: flex;
      }
    }
  }
</style>
