<template>
  <transition name="el-fade-in-linear" appear>
    <nav :class="{ open }" :style="backgroundStyle">
      <button class="back-button unstyled-button" @click="close()">
        <BackArrowSvg :fill="fontColorStyle.color" />
      </button>

      <div class="info">
        <div class="user-info" :style="fontColorStyle">
          <span>Hi, {{ user.firstName }}</span>
          <p>{{ user.registration && user.registration.profileNumber }}</p>
        </div>

        <div class="logo">
          <img :src="$image('system', system.menu_logo)" />
        </div>
      </div>

      <panel class="mt-30">
        <ul class="menu">
          <li
            v-for="item in menu"
            :key="item.text">
            <router-link v-if="item.to" :to="item.to" @click.native="close()">
              <component :is="item.icon" :fill="subnavUnderlineColor" />

              <span>{{ item.text }}</span>
            </router-link>
            <button v-else class="unstyled-button" :disabled="item.disabled" @click="item.onClick">
              <component :is="item.icon" :fill="item.disabled ? $colors.text200 : item.fill || subnavUnderlineColor" />

              <span>{{ item.text }}</span>
            </button>
          </li>
        </ul>
      </panel>

      <div class="submenu flex-between mt-20">
        <router-link v-for="item in subMenu" :key="item.text" :to="item.to" @click.native="close()"  class="box">
          <component :is="item.icon" :fill="subnavUnderlineColor" />
          <span v-html="item.text" />
        </router-link>
      </div>

      <panel class="mt-20">
        <ul class="menu">
          <li
            v-for="item in profileMenu"
            :key="item.text">
            <router-link
              v-if="item.to"
              :to="item.to"
              @click.native="close()">
              <component :is="item.icon" :fill="item.fill || subnavUnderlineColor" />

              <span>{{ item.text }}</span>
            </router-link>

            <button v-else class="unstyled-button" @click="item.onClick">
              <component :is="item.icon" :fill="item.fill || subnavUnderlineColor" />

              <span>{{ item.text }}</span>
            </button>
          </li>
        </ul>
      </panel>
    </nav>
  </transition>
</template>

<script>
import BackArrowSvg from '@/assets/images/icons/back-arrow.svg'
import HomeIcon from '@/assets/images/icons/home.svg'
// import TemplatesIcon from '@/assets/images/icons/templates.svg'
import TradeDeskIcon from '@/assets/images/icons/trade-desk.svg'
import StatementsIcon from '@/assets/images/icons/statements.svg'
import SupportIcon from '@/assets/images/icons/support.svg'
import TransactionsIcon from '@/assets/images/icons/transactions.svg'
import AccountIcon from '@/assets/images/icons/account.svg'
import DepositIcon from '@/assets/images/icons/deposit.svg'
import BankIcon from '@/assets/images/icons/bank.svg'
import ProfileIcon from '@/assets/images/icons/profile.svg'
import PasswordIcon from '@/assets/images/icons/password.svg'
import LogoutIcon from '@/assets/images/icons/logout.svg'

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },

  components: {
    BackArrowSvg
  },

  data () {
    return {
      subMenu: [
        {
          text: 'Account<br>Link',
          icon: AccountIcon,
          to: { name: 'AccountLink' }
        },
        {
          text: 'Deposit Details',
          icon: DepositIcon,
          to: { name: 'DepositPaymentsDetails' }
        },
        {
          text: 'Bank<br>Lookup',
          icon: BankIcon,
          to: { name: 'BankLookup' }
        }
      ],

      profileMenu: [
        {
          text: 'My Profile',
          icon: ProfileIcon,
          to: { name: 'MyProfileDashboard' }
        },
        {
          text: 'Change Password',
          icon: PasswordIcon,
          to: { name: 'ChangePassword' }
        },
        {
          text: 'Logout',
          icon: LogoutIcon,
          fill: this.$colors.danger,
          onClick: this.logout
        }
      ]
    }
  },

  computed: {
    menu () {
      return [
        {
          text: 'Home',
          icon: HomeIcon,
          to: { name: 'Dashboard' }
        },
        {
          text: 'Transactions',
          icon: TransactionsIcon,
          to: { name: 'Transactions' }
        },
        {
          text: 'Trade Desk',
          icon: TradeDeskIcon,
          to: { name: 'TradeDesk' },
          if: this.tradeDeskFeature
        },
        {
          text: 'Statements',
          icon: StatementsIcon,
          to: { name: 'Statements' }
        },
        // {
        //   text: 'Templates',
        //   icon: TemplatesIcon,
        //   // to: { name: 'Templates' },
        //   onClick: () => {},
        //   disabled: true
        // },
        {
          text: 'Support',
          icon: SupportIcon,
          to: { name: 'Support' }
        }
      ].filter(item => item.if === undefined || !!item.if)
    },

    systemFeatures () {
      return this.$store.state.app.systemFeatures
    },

    tradeDeskFeature () {
      return this.systemFeatures['Trade Desk']
    },

    subnavUnderlineColor () {
      return this.theme?.subnavUnderlineColor || this.$colors.info
    },

    system () {
      return this.$store.state.app.system
    },

    fontColorStyle () {
      const color = this.theme ? this.theme.mainColor : 'rgba(64,89,132,1)'
      return {
        color: this.$isColorLight(color) ? this.$colors.black : this.$colors.white
      }
    },

    backgroundStyle () {
      const colorsRight = this.theme ? this.theme.mainColor : 'rgba(64,89,132,1)'
      const colorsLeft = this.$getColorShade(colorsRight, 0.8)
      return {
        background: `linear-gradient(180deg, ${colorsLeft} 0%, ${colorsRight} 100%)`
      }
    },

    theme () {
      return this.$store.getters['app/theme']
    },

    logo () {
      return this.$image('system', this.system.menu_logo)
    },

    verificationZero () {
      return ['REGISTERED', 'ACTIVE'].includes(this.user.status) && !this.user.inVerification
    },

    user () {
      return this.$store.state.app.user
    }
  },

  methods: {
    viewProfile () {
      this.$router.push({ name: 'MyProfileDashboard' })
      this.close()
    },

    changePassword () {
      this.$router.push({ name: 'ChangePassword' })
      this.close()
    },

    async logout () {
      try {
        await this.$confirm('Are you sure you want to log out?', 'Logout?', {
          customClass: 'danger-confirm-modal',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'el-button--danger is-round',
          cancelButtonClass: 'cancel-button is-round',
          type: 'danger',
          center: true
        })
        await this.$store.dispatch('app/logout')
        this.$router.push({ name: 'Login' })
        this.close()
      } catch {}
    },

    close () {
      this.$emit('close')
    }
  },

  created () {
    if (!this.systemFeatures.Withdrawal) {
      this.$router.push({ name: 'Dashboard' })
    }
  }
}
</script>

<style lang="scss" scoped>
nav {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  transform: translateX(100%);
  transition: all ease .3s;
  padding: 10px;
  box-sizing: border-box;

  &.open {
    transform: translateX(0);
  }

  .back-button {
    width: 30px;
  }

  .unstyled-button {
    padding: 5px 0px;
  }

  .info {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    .user-info {
      span {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
      }

      p {
        margin-top: 3px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
      }
    }
    .logo {
      img {
        height: 37px;
      }
    }
  }

  .menu {
    li {
      width: 100%;
      display: flex;
      align-items: center;
      a {
        width: 100%;
        display: block;
        padding: 5px 0;
        color: $title;
      }
      svg {
        vertical-align: middle;
        margin-right: 13px;
      }
      button {
        display: flex;
        align-items: center;
      }
    }
    li + li {
      margin-top: 10px;
    }
  }

  .submenu {
    align-items: stretch;
    .box {
      margin: 0;
      background-color: $surface;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 16px;
      padding: 15px 5px;
      width: calc((100% - 40px) / 3);
      font-size: 16px;
      svg {
        margin-bottom: 15px;
      }
    }

    .box + .box {
      margin-left: 20px;
    }
  }
}
</style>
