<template>
  <div
    class="message"
    :class="{ unread: !message.read }">
    <div class="wrapper">
      <div>
        <span class="date">{{ message.createdAt | date('calendarFull') }}</span>

        <span
          class="text"
          :class="{ bold: !message.read }">
          <el-tag
            v-if="!message.read"
            size="mini"
            type="info"
            rounded>
            {{ message.updatedAt ? 'UPDATED' : 'NEW' }}
          </el-tag>

          {{ message.message }}
        </span>
      </div>

      <div class="buttons">
        <el-tooltip effect="dark" content="Mark as read" placement="top">
          <el-button
            v-if="!message.read"
            type="success"
            size="mini"
            icon="el-icon-check"
            plain
            circle
            @click="$emit('read')" />
        </el-tooltip>
        <el-tooltip v-if="!hideDiscardBtn" effect="dark" content="Discard" placement="top">
          <el-button
            v-if="!message.discarded"
            type="danger"
            size="mini"
            icon="el-icon-close"
            plain
            circle
            @click="$emit('discard')" />
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true
    },

    hideDiscardBtn: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  padding: 7px;
  margin-bottom: 5px;
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .date {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $text;
    }
    .text {
      display: block;
      font-size: 16px;
      color: #0e243a;
      margin-top: 5px;
      flex-shrink: 2;
      &.bold {
        font-weight: bold;
      }
    }
    .buttons {
      flex-shrink: 0;
      margin-left: 10px;
    }
    &.unread {
      background-color: #f9f7ff;
    }
  }
}

.message + .message {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
