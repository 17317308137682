<template>
  <header v-if="user" :style="gradientStyle">
    <main>
      <router-link :to="{ name: 'Dashboard' }">
        <img
          class="logo"
          :alt="system.name"
          :src="logo">
      </router-link>

      <div class="items-center">
        <header-notification-icon class="mr-20" />

        <div
          class="hamburger"
          @click="open = true">
          <i />
          <i />
          <i />
        </div>
      </div>
    </main>

    <div class="content-spacer" />

    <sidebar :open="open" @close="open = false" />
  </header>
</template>

<script>
import Sidebar from './Sidebar'
import HeaderNotificationIcon from '@/components/notifications/HeaderNotificationIcon'

export default {
  components: {
    Sidebar,
    HeaderNotificationIcon
  },

  data () {
    return {
      open: false
    }
  },

  computed: {
    gradientStyle () {
      const colorsRight = this.theme ? this.theme.mainColor : 'rgba(64,89,132,1)'
      const colorsLeft = this.$getColorShade(colorsRight, 0.8)
      return {
        background: `linear-gradient(320deg, ${colorsLeft} 0%, ${colorsRight} 100%)`
      }
    },

    theme () {
      return this.$store.getters['app/theme']
    },

    system () {
      return this.$store.state.app.system
    },

    user () {
      return this.$store.state.app.user
    },

    logo () {
      return this.$image('system', this.system.menu_logo)
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 99;
  background: rgb(30,45,77);

  main {
    height: 85px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
    .logo {
      height: 25px;
      margin-left: 10px;
    }

    .hamburger {
      margin: 0 10px 0 auto;
      cursor: pointer;
      width: 25px;
      height: 20px;
      position: relative;
      i {
        position: absolute;
        display: block;
        width: 25px;
        height: 3px;
        background-color: #fff;
        transition: all ease .2s;
        border-radius: 2px;
        &:first-of-type {
          top: 0;
          margin-top: 0;
        }
        &:nth-child(2) {
          top: 8px;
        }
        &:last-of-type {
          top: 16px;
        }
      }
    }
  }

  .content-spacer {
    width: 100%;
    height: 15px;
    border-top-left-radius: 99px;
    border-top-right-radius: 99px;
    background-color: $bg;
  }
}
</style>
