<template>
  <footer class="page-footer">
    <ul>
      <li v-for="item in menu.filter(item => item.if)" :key="item.label">
        <div class="item" :class="{ disabled: item.disabled, active: isActive(item) }" @click="onItemClick(item)">
          <component v-if="item.icon" :is="item.icon" :style="{ fill: getItemsIconColor(item) }" />
          <label>{{ item.label }}</label>
        </div>
      </li>
    </ul>
  </footer>
</template>

<script>
import HomeSvg from '@/assets/images/icons/home.svg'
import DepositSvg from '@/assets/images/icons/remote-deposit.svg'
import WithdrawalSvg from '@/assets/images/icons/withdrawal.svg'

export default {
  computed: {
    menu  () {
      return [
        {
          icon: DepositSvg,
          label: 'Remote deposit',
          to: { name: '' },
          disabled: true,
          if: this.remoteDepositFeature
        },
        {
          icon: HomeSvg,
          label: 'Home',
          to: { name: 'Dashboard' },
          active: this.$route.name === 'Dashboard',
          if: true
        },
        {
          icon: WithdrawalSvg,
          label: 'Withdrawal',
          to: { name: 'Withdrawal' },
          active: this.$route.name === 'Withdrawal',
          if: this.withdrawalFeature
        }
      ]
    },

    systemFeatures () {
      return this.$store.state.app.systemFeatures
    },

    remoteDepositFeature () {
      return this.systemFeatures['Remote Deposit']
    },

    withdrawalFeature () {
      return this.systemFeatures.Withdrawal
    }
  },

  methods: {
    onItemClick (item) {
      if (item.disabled || !item.to) return
      this.$router.push(item.to)
    },

    getItemsIconColor (item) {
      if (item.disabled) return this.$colors.text200
      if (this.isActive(item)) return this.$colors.info
      return this.$colors.text
    },

    isActive (item) {
      return item.to?.name === this.$route.name
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 60px;
    box-shadow: 0px -15px 10px -20px rgba(0, 0, 0, 0.26);
    ul {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0 15px;
      li {
        width: 100%;
        flex: 1;
        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          label {
            display: block;
            margin-top: 6px;
            text-align: center;
          }
          &.disabled label {
            color: #99AABB;
          }
          &.active label {
            color: #139ADA;
          }
        }
      }
    }
  }
</style>
