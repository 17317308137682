<template>
  <el-popover
    v-model="showMessages"
    ref="popover"
    placement="bottom"
    width="100%"
    class="messages">
    <div>
      <message-item
        v-for="messages in latestMessages"
        :key="messages.id"
        :message="messages"
        hide-discard-btn
        @read="markAsRead(messages.id)" />
      <el-empty
        v-if="!latestMessages.length"
        :image-size="50"
        description="There are no new system messages." />

      <div
        class="see-all">
        <a @click="openAllMessages()">
          View All
        </a>
      </div>
    </div>

    <template #reference>
      <span>
        <el-badge
          v-if="unread"
          :value="unread">
          <img src="@/assets/images/icons/notifications.png" alt="notifications" />
        </el-badge>
        <img
          v-else
          src="@/assets/images/icons/notifications.png" alt="notifications" />
      </span>
    </template>
  </el-popover>
</template>

<script>
import MessageItem from '@/components/other/MessageItem'

export default {
  components: {
    MessageItem
  },

  data () {
    return {
      showMessages: false
    }
  },

  computed: {
    unread () {
      return this.messages.filter(message => !message.read).length
    },

    read () {
      const data = this.$store.state.app.readed
      return data.filter(item => item.itemType === 'SYSTEM_MESSAGES')
    },

    discarded () {
      const data = this.$store.state.app.discarded
      return data.filter(item => item.itemType === 'SYSTEM_MESSAGES')
    },

    messages () {
      const messages = this.$store.state.app.systemMessage
      return messages.slice().sort((a, b) => {
        const aa = parseInt(a.id)
        const bb = parseInt(b.id)
        if (aa > bb) return -1
        if (aa < bb) return 1
        return 0
      }).map(message => {
        return {
          ...message,
          read: this.read.some(read => parseInt(read.itemId) === parseInt(message.id)),
          discarded: this.discarded.some(discarded => parseInt(discarded.itemId) === parseInt(message.id))
        }
      }).filter(message => !message.archived && !message.discarded && !message.read)
    },

    latestMessages () {
      return this.messages.slice(0, this.maxMessages)
    },

    user () {
      return this.$store.state.app.user
    }
  },

  methods: {
    openAllMessages () {
      this.$router.push({ name: 'Messages' })
      this.showMessages = false
    },

    async markAsRead (id) {
      const data = await this.$store.dispatch('readed/create', {
        itemType: 'SYSTEM_MESSAGES',
        itemId: id,
        user: this.user.id
      })
      this.$store.commit('app/addReded', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.see-all {
  text-align: center;
  margin-top: 10px;
}
.messages {
  font-size: 20px;
}
</style>
